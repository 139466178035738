/* eslint-disable max-len */
import ApplicationSerializer from 'shared/serializers/application';

const Mapping = {
  'adapters': ['organization.administration.adapters'],
  'adapter-containers': ['organization.administration.adapters.show.adapter-containers'],
  'alignment': ['workflows.show.workflow-activities.alignments'],
  'alignment-prep': ['workflows.show.workflow-activities.alignment-preps'],
  'asp': ['organization.analytics.asp'],
  'broadcast-event': ['broadcast-event'],
  'bam-qc-prep': ['workflows.show.workflow-activities.bam-qc-preps'],
  'bam-quality-check': ['workflows.show.workflow-activities.bam-quality-checks'],
  'batch-creation': ['workflows.show.workflow-activities.batch-creation'],
  'batch-plating': ['workflows.show.workflow-activities.batch-platings'],
  'box-integration': ['organization.administration.box-integration'],
  'cartridge-data-input': ['workflows.show.workflow-activities.cartridge-data-inputs'],
  'controls': ['organization.administration.controls'],
  'control-containers': ['organization.administration.controls.show.control-containers'],
  'covid': ['organization.analytics.covid', 'organization.analytics.covid.history'],
  'integrations': ['organization.administration.integrations'],
  'custom-qpcr-result': ['workflows.show.workflow-activities.custom-qpcr-results'],
  'demultiplex': ['workflows.show.workflow-activities.demultiplexes'],
  'differential-expression': ['workflows.show.workflow-activities.differential-expressions'],
  'dilution': ['workflows.show.workflow-activities.dilutions'],
  'dna-extraction': ['workflows.show.workflow-activities.dna-extractions'],
  'documents': ['organization.folders'],
  'file-upload': ['workflows.show.workflow-activities.file-upload'],
  'instruments': ['organization.administration.instruments'],
  'infectious-disease': ['organization.analytics.infectious-disease', 'organization.analytics.infectious-disease.history'],
  'ion-reporter-sample-validation': ['workflows.show.workflow-activities.ion-reporter-sample-validation'],
  'library-creation': ['workflows.show.workflow-activities.library-creations'],
  'library-qc-prep': ['workflows.show.workflow-activities.library-qc-preps'],
  'library-quality-check': ['workflows.show.workflow-activities.library-quality-checks'],
  'medication-result': ['workflows.show.workflow-activities.medication-results'],
  'multi-container-pooling': ['workflows.show.workflow-activities.multi-container-pooling'],
  'ngs-dilution': ['workflows.show.workflow-activities.ngs-dilutions'],
  'normalization': ['workflows.show.workflow-activities.normalizations'],
  'org-members': ['organization.administration.members'],
  'org-permissions': null,
  'org-roles': null,
  'org-training-packs': ['organization.administration.training-packs'],
  'org-users': ['organization.administration.users'],
  'plating': ['workflows.show.workflow-activities.platings'],
  'plating-configurations': ['organization.administration.plating-configurations'],
  'pool-aliquot': ['workflows.show.workflow-activities.pool-aliquot'],
  'pool-bam-quality-check': ['workflows.show.workflow-activities.pool-bam-quality-checks'],
  'pool-batch-creation': ['workflows.show.workflow-activities.pool-batch-creations'],
  'pool-sample': ['workflows.show.workflow-activities.pool-samples'],
  'pooled-qc': ['workflows.show.workflow-activities.pooled-qc'],
  'pre-amplification': ['workflows.show.workflow-activities.pre-amplifications'],
  'preferences': ['organization.administration.preferences.show'],
  'project-members': ['projects.show.members'],
  'project-permissions': null,
  'project-roles': null,
  'projects': ['projects'],
  'qc-workflow-reports': ['organization.analytics.qc-workflow-reports', 'organization.analytics.qc-workflow-reports.report', 'organization.analytics.qc-workflow-reports.reports'],
  'qpcr': ['workflows.show.workflow-activities.qpcrs'],
  'qpcr-result': ['workflows.show.workflow-activities.qpcr-results'],
  'quality-check': ['workflows.show.workflow-activities.quality-checks'],
  'release-quality-check': ['workflows.show.workflow-activities.release-quality-checks'],
  'quantification': ['workflows.show.workflow-activities.quantifications'],
  'requisition-permissions': ['requisitions.show'],
  'requisition-roles': null,
  'requisition-templates': ['projects.show.req-settings'],
  'requisitions': ['requisitions', 'projects.show.requisitions'],
  'requisition-reports': ['organization.analytics.requisition-reports', 'organization.analytics.requisition-reports.report', 'organization.analytics.requisition-reports.reports'],
  'reverse-transcription': ['workflows.show.workflow-activities.reverse-transcriptions'],
  'rna-extraction': ['workflows.show.workflow-activities.rna-extractions'],
  'rnase-p': ['workflows.show.workflow-activities.rnase-ps'],
  'rnase-p-quality-check': ['workflows.show.workflow-activities.rnase-p-quality-checks'],
  'sales': null,
  'sample-qc-prep': ['workflows.show.workflow-activities.sample-qc-preps'],
  'sample-quality-check': ['workflows.show.workflow-activities.sample-quality-checks'],
  'sample-status': ['workflows.show.workflow-activities.sample-status'],
  'samples': ['samples', 'requisitions.show.samples', 'projects.show.samples'],
  'sample-types': ['organization.administration.sample-types'],
  'sequencing': ['workflows.show.workflow-activities.sequencings'],
  'sequencing-qc-prep': ['workflows.show.workflow-activities.sequencing-qc-preps'],
  'sequencing-quality-check': ['workflows.show.workflow-activities.sequencing-quality-checks'],
  'shipments': ['shipments'],
  'tat': ['organization.analytics.tat'],
  'test-configurations': ['organization.administration.test-configurations'],
  'test-genes': null,
  'test-panels': ['organization.administration.test-panels'],
  'thermal-cycler': ['workflows.show.workflow-activities.thermal-cyclers'],
  'trainings': ['organization.trainings'],
  'vcf-file-upload': ['workflows.show.workflow-activities.vcf-file-upload'],
  'workflows': ['workflows']
};

export default ApplicationSerializer.extend({

  normalize(typeClass, hash) {
    let { route } = hash.behaviors;
    let key; let prefix; let suffix; let parts;

    let applyMapping = function(mapping) {
      if (suffix) {
        route[`${mapping}.${suffix}`] = route[key];
      } else {
        route[mapping] = route[key];
      }
    };

    for (key in route) {
      prefix = key;
      suffix = null;

      parts = key.match(/([^.]+)\.(.*)/);
      if (parts) {
        prefix = parts[1];
        suffix = parts[2];
      }

      if (Mapping[prefix]) {
        Mapping[prefix].forEach(applyMapping);
      }
    }
    hash.behaviors.route = route;

    return this._super(...arguments);
  }

});
